import type { GetStaticProps } from 'next';
export { default } from '@/routes/notFound';

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      excludeFromSitemap: true
    }
  };
};

import Link from 'next/link';

import { useDocumentTitle } from '@/utils';

import './index.scss';

const NotFound = () => {
  useDocumentTitle('404 Page Not Found');

  return (
    <section className="notFound">
      <video
        className="notFound__video"
        playsInline="playsinline"
        autoPlay="autoplay"
        loop="loop"
        muted
      >
        <source src={require('@/assets/video/404.webm')} type="video/webm" />
        <source src={require('@/assets/video/404.mp4')} type="video/mp4" />
      </video>
      <div className="notFound__inner container">
        <h1 className="notFound__title">404</h1>
        <p className="notFound__subtitle">Page not found</p>
        <p className="notFound__text">
          Oops... The link you clicked may be broken or the page may have been removed. We’re sorry.
        </p>
        <Link className="notFound__link" href="/">
          Back to homepage
        </Link>
      </div>
    </section>
  );
};
export default NotFound;
